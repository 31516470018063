body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
		'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
	height: 100vh;
}

table,
th,
td {
	padding: 6px;
	border: 1px solid rgba(255, 255, 255, 0.1);
	border-collapse: collapse;
}

.sketch-picker label {
	color: #718096 !important;
}

.sketch-picker input {
	border-radius: 5px;
	box-shadow: #718096 0 0 0 1px inset !important;
	background: rgb(23, 25, 35);
}

.sketch-picker div {
	border-top-color: #718096 !important;
}
